import { useEffect, useState } from "react";
import { getProducts } from "../../api";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

import menuIcon from "../../img/menu.svg";
import logo from "../../img/logo.png";
import arrowIcon from "../../img/arrow-right.svg";

import phoneIcon from "../../img/phone-call.svg";
import searchIcon from "../../img/search.svg";
import caseIcon from "../../img/briefcase.svg";
import { CardButton } from "./CardButton";

export const Header = ({ paymentStatus, searchValue, onChangeSearch }) => {
  const nav = useNavigate();

  const [products, setProducts] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [dropdownOpened2, setDropdownOpened2] = useState(false);
  const [dropdownOpened3, setDropdownOpened3] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (paymentStatus === "success") {
      localStorage.removeItem("cart");
      toast.success(
        "Заказ оформлен, наш менеджер в ближайшее время с Вами свяжется",
        {
          duration: 4000,
        }
      );
    } else if (paymentStatus === "error") {
      toast.error("К сожалению, произошла ошибка при оформлении заказа", {
        duration: 4000,
      });
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      nav("/auth");
      return;
    }

    async function fetchData() {
      const products = await getProducts();
      setProducts(products);
      // const user = await getUser();
      // localStorage.setItem("user", JSON.stringify(user));
    }

    fetchData();

    return () => {};
  }, []);

  return (
    <>
      {dropdownOpened3 ? (
        <div
          className="dropdown-wrap"
          onClick={() => {
            setDropdownOpened3(false);
          }}
        >
          <div className="dropdown2">
            Список документов:
            <a
              href="https://spruton.ru/legal/toymarket_service_agreement/"
              target="_blank"
              rel="noreferrer"
            >
              Договор на оказание услуг по размещению товарных предложений на
              сервисе Тоймаркет
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_smz_conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Условия размещения товарных предложений самозанятых на сервисе
              Тоймаркет
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_service_conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Условия оказания услуг Тоймаркет по размещению товарных
              предложений
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_services_rate_table/"
              target="_blank"
              rel="noreferrer"
            >
              Процентные ставки для расчета стоимости услуг и вознаграждения
              Исполнителя (модель DBS)
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_offer_requirements/"
              target="_blank"
              rel="noreferrer"
            >
              Требования к Товарным предложениям и их формату
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_adv_rules/"
              target="_blank"
              rel="noreferrer"
            >
              Требования к материалам
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_termsofuse/"
              target="_blank"
              rel="noreferrer"
            >
              Правила использования сервиса Тоймаркет
            </a>
            <a
              href="https://spruton.ru/legal/toymarket_license_agreement/"
              target="_blank"
              rel="noreferrer"
            >
              Лицензионное соглашение на использование сервиса Тоймаркет
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
      {dropdownOpened2 ? (
        <div
          className="dropdown-wrap"
          onClick={() => {
            setDropdownOpened2(false);
          }}
        >
          <div className="dropdown2">
            <span>По коммерческим вопросам:</span>
            <a href={"tel:+79784514771"}>+7(978)45-14-771</a>
            <a href="mailto:partners@octobyte.ru">partners@octobyte.ru</a>
            <span>По техническим вопросам: </span>
            <a href={"tel:+79786121068"}>+7(978)61-21-068</a>
            <a href="mailto:support@octobyte.ru">support@spruton.shop</a>
            <span className="bold">
              Мы всегда готовы ответить на ваши вопросы.
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className="container box"
        onClick={() => {
          if (dropdownOpened) {
            setDropdownOpened(false);
          }
          if (dropdownOpened2) {
            setDropdownOpened2(false);
          }
          if (dropdownOpened3) {
            setDropdownOpened3(false);
          }
        }}
      >
        <div className="menu">
          <div className="logo">
            <img
              src={logo}
              className="logoIcon"
              alt="logo"
              onClick={() => nav("/")}
            />
          </div>

          <div className="search">
            <input
              placeholder="Поиск..."
              value={searchValue || searchInput}
              onChange={(e) => {
                onChangeSearch?.(e.target.value);
                setSearchInput(e.target.value);
              }}
            />
            <div
              className="icon"
              onClick={() => {
                nav(`/search?q=${searchInput}`);
              }}
            >
              <img src={searchIcon} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="bottomBar">
              <div className={`icon ${dropdownOpened2 && "activeIcon"}`}>
                <img
                  src={phoneIcon}
                  onClick={() => {
                    setDropdownOpened2(!dropdownOpened2);
                  }}
                />
              </div>
              <div className={`icon ${dropdownOpened3 && "activeIcon"}`}>
                <img
                  src={caseIcon}
                  onClick={() => {
                    setDropdownOpened3(!dropdownOpened3);
                  }}
                />
              </div>
              <CardButton />
            </div>

            <div className={`menuIcon ${dropdownOpened && "activeIcon"}`}>
              <img
                src={menuIcon}
                onClick={() => {
                  setDropdownOpened(!dropdownOpened);
                }}
              />
            </div>
          </div>
        </div>
        {dropdownOpened ? (
          <div className="dropdown">
            <div
              className="item"
              onClick={() => {
                nav("/orders");
              }}
            >
              История заказов <img src={arrowIcon} />
            </div>
            {products
              .map((category, index) => ({ originalIndex: index, category }))
              .sort((a, b) => {
                if (a.category.categoryName === "Новинки") return -1;
                if (b.category.categoryName === "Новинки") return 1;
                return a.category.categoryName.localeCompare(
                  b.category.categoryName
                );
              })
              .map((sortedCategory, sortedIndex) => {
                const { category, originalIndex } = sortedCategory;
                const filteredProducts = category.products.filter(
                  (product) => product.price && parseInt(product.price) !== 0
                );
                if (filteredProducts.length === 0) return null;
                return (
                  <div
                    className="item"
                    onClick={() => {
                      nav("/category/" + originalIndex);
                    }}
                    key={sortedIndex}
                  >
                    {category.categoryName}
                    <img src={arrowIcon} />
                  </div>
                );
              })}
            <br />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
