import { useEffect } from "react";
import { useNavigate } from "react-router";
import logo from "../img/logo.png";
import { LoginButton } from "@telegram-auth/react";

const AuthTelegram = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      nav("/");
      return;
    }
  }, []);
  const login = async () => {
    //   localStorage.setItem("login", data.login);
    //   localStorage.setItem("password", data.password);
    // const user = await getUser();
    // if (user) {
    //   nav("/");
    // } else {
    //   localStorage.removeItem("login");
    //   localStorage.removeItem("password");
    //   window.location.reload();
    // }
  };

  return (
    <>
      <div className="container-order-data">
        <div className="order-form order-form-auth">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} className="logoIcon" alt="logoIcon" />
          </div>

          <main className="telegram-wrapper">
            <LoginButton
              botUsername="test_toyseller_bot"
              // authCallbackUrl="/"
              buttonSize="large" // "large" | "medium" | "small"
              cornerRadius={5} // 0 - 20
              showAvatar={false}
              lang="ru"
              onAuthCallback={(data) => {
                localStorage.setItem("user", JSON.stringify(data));
                nav("/");
              }}
            />
            <p className="politic">
              Авторизовываясь на маркетплейсе Тоймаркет через сервис Telegram,
              Вы соглашаетесь с
              <a
                href="https://spruton.ru/legal/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Политикой конфиденциальности{" "}
              </a>
              и
              <a
                href="https://spruton.ru/legal/rules/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Пользовательским соглашением{" "}
              </a>
              платформы СПРУТОН
            </p>
          </main>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="footer-auth">
              Работает на платформе
              <a href="https://spruton.ru/" target="_blank" rel="noreferrer">
                {" "}
                СПРУТОН{" "}
              </a>
              Интегратор
              <a href="https://octobyte.ru/" target="_blank" rel="noreferrer">
                {" "}
                Октобайт{" "}
              </a>
            </p>
            {/* <p>
              Техподдержка:{" "}
              <a href="mailto:support@spruton.shop">support@spruton.shop</a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthTelegram;
