import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import arrow2Icon from "../../img/arrow-right.svg";
import { Header } from "../header/Header";
import { getUser } from "../../api";
import { useAppContext } from "../contextWrapper/AppContext";
import { getDeclination } from "../../utils/getDeclination";

import "./Orders.css";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Orders = () => {
  const nav = useNavigate();

  const { userInfo, setUserInfo } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUser();
      localStorage.setItem("userInfo", JSON.stringify(user));
      setUserInfo(user);
    };

    fetchData();
  }, []);

  return (
    <div className="container box">
      <Header />
      <div className="title-block">
        <div className="card-block-element-title">
          <h3>История заказов</h3>
          <span>
            {getDeclination(userInfo?.orders?.length, [
              "заказ",
              "заказа",
              "заказов",
            ])}
          </span>
        </div>
      </div>

      <div className="container-order cart mt-25 order">
        {userInfo?.orders &&
          userInfo?.orders.map((order) => (
            <div
              className="cart-item-row"
              style={{ borderBottom: "2px solid #1c1c1c" }}
              onClick={() => {
                localStorage.setItem("order", JSON.stringify(order));
                nav(`/orderInfo/${order.orderId}`);
              }}
            >
              <div
                className="cart-item-data"
                style={{
                  alignItems: "center",
                }}
              >
                <div className="cart-item-label">
                  <h3>Заказ #{order.orderId}</h3>
                  <div className="info-order">
                    <span className="gray-7d fs-18">Дата заказа:</span>
                    <span className="gray-d9 fs-18">
                      {new Date(order.orderDate * 1000).toLocaleString()}
                    </span>
                    <span className="gray-7d fs-18">Кол-во товаров:</span>
                    <span className="gray-d9 fs-18">
                      {order.products.length}
                    </span>
                  </div>
                  <span className="price-orders">
                    {formatNumberWithSpaces(parseInt(order.total))} ₽
                  </span>
                </div>
                <button
                  onClick={() => {
                    localStorage.setItem("order", JSON.stringify(order));
                    nav(`/orderInfo/${order.orderId}`);
                  }}
                >
                  Подробнее
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Orders;
