import { useState, useEffect } from "react";
import "./ButtonBlock.css";
import { calculatePrice } from "../../utils/calculatePrice";
import { useNavigate } from "react-router";
import { useAppContext } from "../contextWrapper/AppContext";

export const ButtonBlock = ({ product }) => {
  // const [cart, setCart] = useState([]);
  const { cart, setCart } = useAppContext();
  const [inCart, setInCart] = useState({});
  const price = calculatePrice(inCart, product);
  const nav = useNavigate();

  useEffect(() => {
    if (cart.length > 0) {
      setInCart({ ...cart.find((item) => item.id === product.id) });
    }
  }, [cart]);

  // useEffect(() => {
  //   localStorage.setItem("cart", JSON.stringify(cart));
  // }, [cart]);

  const handleDecrement = () => {
    if (inCart.quantity <= 0) return;

    let minusAmount = 1;
    if (parseInt(product.inBox) >= parseInt(inCart.quantity * product.inBox)) {
      minusAmount = 1 / (parseInt(product.inBox) / parseInt(product.inPackage));
    } else if (
      parseInt(product.inBox) + parseInt(product.inTheBox) <=
      parseInt(inCart.quantity * product.inBox)
    ) {
      minusAmount = parseInt(product.inTheBox) / parseInt(product.inBox);
    }

    const newQuantity =
      inCart.quantity > minusAmount
        ? parseFloat(inCart.quantity - minusAmount)
        : 0;

    if (newQuantity > 0) {
      const newCart = cart.map((item) =>
        item.id === product.id ? { ...item, quantity: newQuantity } : item
      );
      setCart(newCart);
      setInCart({ ...inCart, quantity: newQuantity });
    } else {
      const newCart = cart.filter((item) => item.id !== product.id);
      setCart(newCart);
      setInCart({});
    }
  };

  const handleIncrement = () => {
    const maxQuantity =
      product.inStock * (parseInt(product.inTheBox) / parseInt(product.inBox));
    if (inCart.quantity >= maxQuantity) return;

    let incrementAmount = 1;
    if (parseInt(product.inBox) > parseInt(inCart.quantity * product.inBox)) {
      incrementAmount =
        1 / (parseInt(product.inBox) / parseInt(product.inPackage));
    }

    const newQuantity = inCart.quantity + incrementAmount;

    if (inCart.quantity > 0) {
      const newCart = cart.map((item) =>
        item.id === product.id ? { ...item, quantity: newQuantity } : item
      );
      console.log(newCart, "newCart");

      setCart(newCart);
      setInCart({ ...inCart, quantity: newQuantity });
    } else {
      const newCart = [...cart, { ...product, quantity: incrementAmount }];
      setCart(newCart);
      setInCart({ ...product, quantity: incrementAmount });
    }
  };

  const addToCart = () => {
    if (inCart.quantity > 0) {
      nav("/cart");
    } else {
      const newCart = [...cart, { ...product, quantity: 1 }];
      setCart(newCart);
      setInCart({ ...product, quantity: 1 });
    }
  };

  return (
    <div className="cart-container">
      {inCart.quantity > 0.01 && (
        <div className="counter-container">
          <button className="counter-button" onClick={handleDecrement}>
            <svg viewBox="0 0 341.333 341.333" className="counter-icon">
              <rect
                y="149.333"
                width="341.333"
                height="42.667"
                fill="currentColor"
              />
            </svg>
          </button>
          <span className="counter-value">
            {parseInt(inCart.quantity * product.inBox) % product.inPackage !== 0
              ? Math.ceil(inCart.quantity * product.inBox)
              : parseInt(inCart.quantity * product.inBox)}
          </span>
          <button className="counter-button" onClick={handleIncrement}>
            <svg viewBox="0 0 341.4 341.4" className="counter-icon">
              <polygon
                points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      )}
      {parseInt(product.inStock) > 0 ? (
        <button className="add-button" onClick={addToCart}>
          {inCart.quantity > 0 ? (
            <>
              В Корзине <span className="price-span">на {price} р</span>
            </>
          ) : (
            "Добавить"
          )}
        </button>
      ) : (
        <button className="out-of-stock-button" disabled>
          Нет в наличии
        </button>
      )}
      <div className="price-block">
        <h3>{product.price} Р</h3>
        <span className="old-price">{product.discountedPrice} р</span>
        {product.personalDiscount && (
          <span className="percent">{product.personalDiscount} %</span>
        )}
      </div>
    </div>
  );
};
