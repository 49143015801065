import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import arrowIcon from "../img/arrow-left.svg";

const Contact = () => {
  const nav = useNavigate();

  return (
    <>
      <div className="container-order-data">
        <div className="order-form">
          <p className="order-data-page-title">
            {" "}
            <img
              src={arrowIcon}
              onClick={() => {
                nav("/");
              }}
            />
            Контакты
          </p>

          <p>
            По всем вопросам обращаться по телефону:{" "}
            <a href={"tel:+79786121068"}>+79786121068</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
