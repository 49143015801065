import { useNavigate } from "react-router";
import cartIcon from "../../img/shopping-cart.svg";
import { useAppContext } from "../contextWrapper/AppContext";

export const CardButton = () => {
  //   const cardLength = cart && cart.length;
  const nav = useNavigate();
  // нужно переделать логику подсчета корзины
  const { cart } = useAppContext();

  return (
    <div className="icon">
      <img
        src={cartIcon}
        onClick={() => {
          nav("/cart");
        }}
      />
      {cart && cart.length ? (
        <div className="card-count-number">{cart.length}</div>
      ) : null}
    </div>
  );
};
