import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Header } from "../header/Header";
import "./Cart.css";
import { DeleteIcon } from "../icons/DeleteIcon";
import { DeliveryInfoBlock } from "./DeliveryInfoBlock";
import { PayTypeDeliveryBlock } from "./PayTypeDeliveryBlock";
import { TotalBlock } from "./TotalBlock";
import { getDeclination } from "../../utils/getDeclination";
import { formatNumberWithSpaces } from "../../utils/formatNumberWithSpaces";
import { useAppContext } from "../contextWrapper/AppContext";

const NewCart = () => {
  const nav = useNavigate();
  const { cart, setCart } = useAppContext();
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryData, setDeliveryData] = useState("1");
  const [paymentData, setPaymentData] = useState("3");

  useEffect(() => {
    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  const deleteCart = async () => {
    setCart([]);
  };

  return (
    <div className="container box">
      <Header />
      <div className="card-block">
        <div className="left-card-block">
          <div className="card-block-element list">
            <div className="title-block">
              <div className="card-block-element-title">
                <h3>Корзина</h3>
                <span>
                  {getDeclination(cart.length, ["товар", "товара", "товаров"])}{" "}
                </span>
              </div>
              <button onClick={() => deleteCart()}>
                Очистить корзину
                <DeleteIcon />
              </button>
            </div>
            <div className="card-block-list">
              {cart.map((product) => (
                <div className="cart-item-row">
                  <div
                    className="cart-item-picture"
                    onClick={() => {
                      if (parseInt(product.inStock <= 0)) return;
                      localStorage.setItem(
                        "product",
                        JSON.stringify({
                          ...product,
                        })
                      );
                      nav("/product/" + product.id);
                    }}
                  >
                    <img
                      src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                      alt="picture"
                    />
                  </div>
                  <div className="cart-item-data">
                    <div className="cart-item-label">
                      {product.article}
                      <div className="cart-item-caption">
                        <span>характеристики добавить</span>
                        <span>PM3: {product.inBox} шт</span>
                        <DeleteIcon
                          onClick={() => {
                            setCart(cart.filter((i) => i.id !== product.id));
                          }}
                        />
                      </div>
                    </div>
                    <div className="cart-right-block">
                      {Number(product.inStock) > 0 ? (
                        <div
                          className="cart-item-counter"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className="cic-minus"
                            onClick={() => {
                              let minusAmount = 1;
                              if (
                                parseInt(product.inBox) >=
                                parseInt(product.quantity * product.inBox)
                              ) {
                                minusAmount =
                                  1 /
                                  (parseInt(product.inBox) /
                                    parseInt(product.inPackage));
                              } else if (
                                parseInt(product.inBox) +
                                  parseInt(product.inTheBox) <=
                                parseInt(product.quantity * product.inBox)
                              ) {
                                minusAmount =
                                  parseInt(product.inTheBox) /
                                  parseInt(product.inBox);
                              }

                              const newQuantity =
                                product.quantity.toFixed(3) >
                                minusAmount.toFixed(3)
                                  ? parseFloat(product.quantity - minusAmount)
                                  : 0;

                              if (newQuantity > 0) {
                                setCart(
                                  cart.map((_product) => {
                                    if (_product.id === product.id) {
                                      return {
                                        ..._product,
                                        quantity: newQuantity,
                                      };
                                    }
                                    return _product;
                                  })
                                );
                              } else {
                                setCart(
                                  cart.filter(
                                    (_product) => _product.id != product.id
                                  )
                                );
                              }
                            }}
                          >
                            <svg
                              className="svg"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0"
                              y="0"
                              viewBox="0 0 341.333 341.333"
                              style={{
                                "enable-background": "new 0 0 512 512",
                              }}
                              xmlSpace="preserve"
                            >
                              <rect
                                y="149.333"
                                width="341.333"
                                height="42.667"
                                fill="#f6f6f6"
                              ></rect>
                            </svg>
                          </div>
                          <div className="cic-count">
                            {parseInt(product.quantity * product.inBox) %
                              product.inPackage !==
                            0
                              ? Math.ceil(product.quantity * product.inBox)
                              : parseInt(product.quantity * product.inBox)}
                          </div>
                          <div
                            className="cic-plus"
                            onClick={() => {
                              if (
                                product.quantity <
                                product.inStock *
                                  (parseInt(product.inTheBox) /
                                    parseInt(product.inBox))
                              ) {
                                let incrementAmount = 1;
                                if (
                                  parseInt(product.inBox) >
                                  parseInt(product.quantity * product.inBox)
                                ) {
                                  incrementAmount =
                                    1 /
                                    (parseInt(product.inBox) /
                                      parseInt(product.inPackage)); //parseInt(product.inPackage)
                                }
                                setCart(
                                  cart.map((_product) => {
                                    if (_product.id === product.id) {
                                      return {
                                        ..._product,
                                        quantity: parseFloat(
                                          (
                                            _product.quantity + incrementAmount
                                          ).toFixed(2)
                                        ),
                                      };
                                    }
                                    return _product;
                                  })
                                );
                              }
                            }}
                          >
                            <svg
                              className="svg"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0"
                              y="0"
                              viewBox="0 0 341.4 341.4"
                              style={{
                                "enable-background": "new 0 0 512 512",
                              }}
                              xmlSpace="preserve"
                            >
                              <polygon
                                points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                                fill="#f6f6f6"
                                className=""
                              ></polygon>
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <div className="cart-item-counter notqqq">
                          <div>Нет в наличии</div>
                        </div>
                      )}

                      <span className="cart-item-price">
                        {/* цена */}
                        {formatNumberWithSpaces(
                          parseInt(product.quantity * product.inBox) %
                            product.inPackage !==
                            0
                            ? Math.ceil(product.quantity * product.inBox) *
                                product.price
                            : parseInt(product.quantity * product.inBox) *
                                product.price
                        )}{" "}
                        ₽
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <DeliveryInfoBlock />
        </div>
        <div className="right-card-block">
          <TotalBlock
            cart={cart}
            setCart={setCart}
            deliveryData={deliveryData}
            paymentData={paymentData}
          />
          <PayTypeDeliveryBlock
            paymentData={paymentData}
            setPaymentData={setPaymentData}
            deliveryData={deliveryData}
            setDeliveryData={setDeliveryData}
          />
        </div>
      </div>
    </div>
  );
};

export default NewCart;
