import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Catalog from "./views/Catalog";
import NewCart from "./views/cart/NewCart";
import Category from "./views/Category";
import Contact from "./views/Contact";
import Terms from "./views/Terms";
import Orders from "./views/orders/Orders";
import OrderInfo from "./views/orders/OrderInfo";
import Search from "./views/Search";
import { Toaster } from "react-hot-toast";
import AuthTelegram from "./views/AuthTelegram";
import { NewProduct } from "./views/product/NewProduct";

const App = () => {
  useEffect(() => {
    if (
      !localStorage.getItem("user") &&
      window &&
      !window.location.href.includes("/auth")
    ) {
      window.location.href = "/auth";
    }
  }, []);

  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/auth" element={<AuthTelegram />} />
        <Route path="/" element={<Catalog />} />
        <Route path="/cart" element={<NewCart />} />
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/* <Route path="/order" element={<Order />} /> */}
        <Route path="/product/:id" element={<NewProduct />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/orderInfo/:id" element={<OrderInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/search" element={<Search />} />
        <Route
          path="/hook/payment/success"
          element={<Catalog paymentStatus={"success"} />}
        />
        <Route
          path="/hook/payment/fail"
          element={<Catalog paymentStatus={"error"} />}
        />
        <Route path="*" element={<Catalog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
